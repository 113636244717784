import * as lib from './lib'

const popup = lib.getNode('.popup');
function showPopup() {
    popup.style.display = 'flex';
    const body = lib.getNode('body');
    body.style.overflowY = 'hidden';
}
function hidePopup() {
    popup.style.display = 'none';
    const body = lib.getNode('body');
    body.style.overflowY = 'scroll';
}
lib.handleNodesClick('.popup-close-btn', () => {
    hidePopup();
});
lib.handleNodesClick('.popup-container', e => {
    e.stopPropagation();
});
lib.handleNodesClick('.popup', () => {
    hidePopup();
});
const popupContent = lib.getNode('.popup-content');

lib.handleNodesClick('#footer-docs', () => {
    popupContent.innerHTML = `
        <h3>Документация</h3>
        
        <div class="popup-text footer-docs-content">
            <p>
                <span>Руководство пользователя ПО Rubeacon</span>
                <a href="https://docs.google.com/document/d/19mDJVWNbnM8un-8QZBq0vzBYqHA5u3pb5PEMXAINF_M/edit?usp=sharing" target="_blank">Просмотреть</a>
            </p>
            <p>
                <span>Жизненный цикл ПО Rubeacon</span>
                <a href="https://docs.google.com/document/d/1vprmiCAgCaIUpDQpr-1Z5KHZeaGkm-0rnAHTxJsLABI/edit?usp=sharing" target="_blank">Просмотреть</a>
            </p>
            <p>
                <span>Документация, содержащая описание технических средств хранения исходного текста и объектного кода программного обеспечения, а также технических средств компиляции исходного текста в объектный код программного обеспечения</span>
                <a href="https://docs.google.com/document/d/1QA0C9sUzKii71xZNYa_C-sXK7wscO2da0_bwcP-QyT8/edit?usp=sharing" target="_blank">Просмотреть</a>
            </p>
            <p>
                <span>Описание технической архитектуры ПО Rubeacon</span>
                <a href="https://docs.google.com/document/d/1SVRi1V7OhJiZev3pIcTvIVdptWxUF6umjQeMg7rsMXU/edit?usp=sharing" target="_blank">Просмотреть</a>
            </p>
            <p>
                <span>Описание функциональных характеристик ПО Rubeacon</span>
                <a href="https://docs.google.com/document/d/1IoTYWEF0VcHmnd5WcM8wmYff01U73bXklZ4tTP5gxIo/edit?usp=sharing" target="_blank">Просмотреть</a>
            </p>
            
        </div>
    `;
    showPopup();
});

lib.handleNodesClick('#consent', () => {
    popupContent.innerHTML = `
        <h3>Согласие на обработку персональных данных</h3>
        
        <div class="popup-text">
            <p>
                Я даю свое Согласие Обществу с ограниченной ответственностью «РуБикон» (ИНН/КПП: 7728881950/771001001, ОГРН
                1147746697075) и организациям, привлекаемым ООО «РуБикон» на договорной основе (далее по тексту – ООО «РуБикон», на
                обработку своих персональных данных в сервисе обратной связи и технической поддержки Pyrus, куда заявки попадают
                посредством отправки письма по email на адрес help@ru-beacon.ru в соответствии с Федеральным законом от 27.02.2006 №
                152-ФЗ «О персональных данных» (далее по тексту – Согласие на обработку ПД). Мое Согласие на обработку ПД включает в
                том числе возможность автоматизированной обработки ООО «РуБикон» моих персональных данных.
            </p>
            
            <p>
                Я ознакомлен(а), что под обработкой персональных данных в указанном законе понимаются действия (операции) с
                персональными данными, включая сбор, запись, систематизацию, накопление, хранение, уточнение (обновление,
                изменение), извлечение, использование, передачу (распространение, предоставление, доступ), обезличивание,
                блокирование, удаление, уничтожение персональных данных.
            </p>
            
            <p>
                Разрешаю обработку моих персональных данных ООО «РуБикон» в целях:
                <br>
                1. оказания консультаций по программному обеспечению созданного ООО «РуБикон» мобильного приложения и партнерского
                программного обеспечения, а также оказания иных услуг, выполнения работ по направляемому мной обращению;
                <br>
                2. возможного информировании меня о продуктах и услугах, предложения мне продуктов и услуг ООО «РуБикон»;
                <br>
                3. в целях участия в опросах/анкетировании, проводимых ООО «РуБикон» для изучения и исследования мнения клиентов о
                качестве обслуживания и услугах/работах ООО «РуБикон».
            </p>
            
            <p>
                Гарантирую, что представленная мной информация является полной, точной и достоверной в запрашиваемых ООО «РуБикон»
                пределах, а также, что при представлении информации не нарушаются действующее законодательство Российской Федерации,
                законные права и интересы третьих лиц. Вся представленная информация заполнена мною в отношении себя лично.
            </p>
            
            <p>
                Настоящее согласие действует в течение всего периода хранения персональных данных, если иное не предусмотрено
                законодательством Российской Федерации.
            </p>
        </div>
    `;
    showPopup();
});

lib.handleNodesClick('#privacy', () => {
    popupContent.innerHTML = `
        <h3>Политика конфиденциальности персональной информации пользователей</h3>
        
        <div class="popup-text">
            <div>
                <p>
                    Политика конфиденциальности персональной информации (далее — Политика) действует в отношении любой информации,
                    которую Общество с ограниченной ответственностью «РуБикон» (ИНН/КПП: 7728881950/771001001, ОГРН 1147746697075,
                    расположенное по адресу 123056, Россия, г. Москва, ул. 2-я Брестская, д. 19/18с5) и/или его
                    контрагенты
                    в рамках договорных отношений с ООО «РуБикон» (далее по тексту – RuBeacon) может получить во время правомерного
                    использования Пользователем Приложений, программного обеспечения и компьютерных информационных сервисов RuBeacon
                    (далее по тексту - ПО RuBeacon), а так же в ходе исполнения RuBeacon любых соглашений, договоров, контрактов с
                    Пользователем.
                </p>
                <p>
                    Правила настоящей «Политики конфиденциальности персональной информации пользователей» (далее по тексту –
                    Политика
                    конфиденциальности или Политика) применяются в отношении любого программного обеспечения, приложений,
                    публикуемых и
                    выпускаемых под товарным знаком «RuBeacon», а также любые иные компьютерные сервисы и интернет-сайты, владельцем
                    которых является ООО «РуБикон».
                </p>
                <p>
                    Согласие Пользователя с настоящей Политикой конфиденциальности, выраженное им в рамках отношений с ООО
                    «РуБикон»,
                    распространяется на всех его контрагентов в рамках договорных отношений с ООО «РуБикон», правомерно использующих
                    Приложения, программное обеспечение и информационные сервисы RuBeacon.
                </p>
                <p>
                    Использование ПО RuBeacon означает безоговорочное согласие Пользователя с настоящей Политикой и указанными в ней
                    условиями и порядком обработки его персональной информации.
                </p>
                <p>
                    В случае несогласия с этими условиями Пользователь должен отказаться от использования ПО RuBeacon.
                </p>
            </div>
        
            <hr>
            
            <b>1. Персональная информация Пользователей, которую обрабатывает или может обрабатывать RuBeacon.</b><br><br>
            1.1. В рамках настоящей Политики под «персональной информацией Пользователя» понимаются:<br><br>
            
            1.1.1. Персональная информация, которую Пользователь предоставляет о себе самостоятельно при создании учётной записи
            (регистрации) или в процессе использования ПО RuBeacon, в том числе персональные данные Пользователя. Обязательная для
            предоставления ПО RuBeacon информация имеет специальную отметку. Прочая информация предоставляется Пользователем на
            усмотрение Пользователя.<br><br>
            
            1.1.2. Данные, которые автоматически передаются ПО RuBeacon в процессе их использования с помощью установленного на
            устройстве Пользователя программного обеспечения, в том числе:<br><br>
            
            1) IP-адрес (если применимо);<br><br>
            
            2) данные файлов cookie (если применимо);<br><br>
            
            3) информация о браузере Пользователя или иной программе, с помощью которой осуществляется доступ к ПО RuBeacon (если
            применимо);<br><br>
            
            4) технические характеристики оборудования и программного обеспечения, используемых Пользователем (если применимо);<br><br>
            
            5) дата и время доступа к ПО RuBeacon (если применимо);<br><br>
            
            6) адреса запрашиваемых страниц (если применимо);<br><br>
            
            7) территориальное местонахождение Пользователя в определённом месте в определенное время суток (если применимо);<br><br>
            
            8) Скриншоты экрана Пользователя при возникновении ошибок могут автоматически отправляться RuBeacon для последующего
            устранения ошибок ПО RuBeacon и/или в статистических целях (если применимо);<br><br>
            
            9) и иная информация, необходимая для полноценного функционирования, соответствующего ПО RuBeacon;<br><br>
            
            1.1.3. Иная информация о Пользователе, обработка которой предусмотрена условиями использования ПО RuBeacon.<br><br>
            
            1.2. Настоящая Политика применима только к информации, обрабатываемой в ходе использования ПО RuBeacon. RuBeacon не
            контролирует и не несет ответственность за обработку информации программным обеспечением и информационными сервисами
            третьих лиц.<br><br>
            
            1.3. RuBeacon исходит из того, что Пользователь при использовании ПО RuBeacon предоставляет достоверную и достаточную
            персональную информацию и поддерживает такую информацию в актуальном состоянии. В случае предоставления Пользователем
            недостоверной или недостаточной информации вся ответственность за неработоспособность ПО RuBeacon или иные технические
            или юридические риски несет Пользователь.<br><br>
            
            <hr>
            
            <b>2. Цели обработки персональной информации Пользователей.</b><br><br>
            
            2.1. RuBeacon собирает и хранит только ту персональную информацию, которая необходима для использования ПО RuBeacon или
            исполнения соглашений, договоров, контрактов с Пользователем, за исключением случаев, когда применимым законодательством
            предусмотрено обязательное хранение персональной информации в течение определенного законодательством срока.<br><br>
            
            2.2. Персональную информацию Пользователя RuBeacon обрабатывает в следующих целях:<br><br>
            
            2.2.1. Идентификация пользователя в рамках использования ПО RuBeacon, а так же в рамках соглашений, договоров,
            контрактов с RuBeacon (если применимо);<br><br>
            
            2.2.2. Предоставление Пользователю возможности использовать ПО RuBeacon персонализировано, а также исполнение
            соглашений, договоров, контрактов с RuBeacon (если применимо);<br><br>
            
            2.2.3. Связь с Пользователем, в том числе направление уведомлений, запросов и информации, касающихся использования ПО
            RuBeacon и при исполнения соглашений, договоров, контрактов с RuBeacon (если применимо), а также обработка заявок,
            запросов, обращений от Пользователя (если применимо);<br><br>
            
            2.2.4. Улучшение качества ПО RuBeacon, удобства его использования, разработка нового ПО RuBeacon;<br><br>
            
            2.2.5. Таргетирование рекламной информации (если применимо);<br><br>
            
            2.2.6. Проведение статистических и иных исследований, на основе обезличенных персональных данных (если применимо).<br><br>
            
            <hr>
            
            
            <b>3. Условия обработки персональной информации Пользователей и её передача третьим лицам.</b><br><br>
            
            3.1. RuBeacon хранит персональную информацию Пользователей в соответствии с полученным от Пользователя соответствующим
            согласием на обработку персональной информации для конкретного ПО RuBeacon.<br><br>
            
            3.2. В отношении персональной информации Пользователя сохраняется конфиденциальность такой персональной информации,
            кроме случаев, когда Пользователь добровольно предоставил такую информацию о себе для общего доступа неограниченному
            кругу лиц. В таком случае, при использовании некоторых сервисов и функций ПО RuBeacon, Пользователь соглашается с тем,
            что определённая часть его персональной информации становится общедоступной.<br><br>
            
            3.3. Пользователь соглашается с тем, что RuBeacon вправе передать персональную информацию Пользователя третьим лицам в
            следующих случаях:<br><br>
            
            3.3.1. Пользователь выразил согласие на такие действия;<br><br>
            
            3.3.2. Передача необходима для использования Пользователем определенного Сервиса либо функции ПО RuBeacon, либо для
            исполнения определенного соглашения, договора, контракта с Пользователем;<br><br>
            
            3.3.8. Передача предусмотрена российским или иным применимым законодательством в рамках установленной соответствующим
            законодательством процедуры;<br><br>
            
            3.3.9. Такая передача происходит в рамках продажи или иной передачи бизнеса (полностью или частично), при этом к
            приобретателю переходят все обязательства по соблюдению условий настоящей Политики применительно к полученной им
            персональной информации;<br><br>
            
            3.3.10. В целях обеспечения возможности защиты прав и законных интересов RuBeacon или третьих лиц в случаях, когда
            Пользователь нарушает Пользовательское соглашение соответствующего ПО RuBeacon, настоящую Политику, либо иные документы,
            содержащие условия использования конкретного ПО RuBeacon (если применимо).<br><br>
            
            3.3.11. В результате обработки персональной информации Пользователя путем ее обезличивания получены обезличенные
            статистические данные, которые передаются третьему лицу для проведения исследований, выполнения работ или оказания услуг
            по поручению RuBeacon.<br><br>
            
            3.4. При обработке персональных данных Пользователей RuBeacon руководствуется Федеральным законом РФ «О персональных
            данных» и иным применимым законодательством.<br><br>
            
            <hr>
            
            <b>4. Изменение и удаление персональной информации. Обязательное хранение данных.</b><br><br>
            
            4.1. Пользователь может по своему усмотрению в любой момент изменить (обновить, дополнить) предоставленную им
            персональную информацию или её часть, воспользовавшись функцией редактирования персональных данных в соответствующем
            разделе ПО RuBeacon.<br><br>
            
            4.2. Пользователь также может удалить предоставленную им персональную информацию в рамках использования соответствующего
            ПО RuBeacon, отправив соответствующее уведомление на почту help@ru-beacon.ru. При этом удаление аккаунта/личного
            кабинета может повлечь невозможность использования ПО RuBeacon.<br><br>
            
            4.3. Права, предусмотренные пп. 4.1. и 4.2. настоящей Политики могут быть ограничены в соответствии с требованиями
            применимого законодательства. В частности, такие ограничения могут предусматривать обязанность RuBeacon сохранить
            измененную или удаленную Пользователем информацию на срок, установленный законодательством, и передать такую информацию
            в соответствии с законодательно установленной процедурой государственному органу.<br><br>
            
            <hr>
            
            
            <b>5. Обработка персональной информации при помощи файлов Cookie и счетчиков (если применимо).</b><br><br>
            
            5.1. Файлы cookie, передаваемые ПО RuBeacon оборудованию Пользователя и оборудованием Пользователя в RuBeacon, могут
            использоваться RuBeacon для предоставления Пользователю персонализированных Сервисов и функций ПО RuBeacon, для
            персонализации рекламы, которая может показываться Пользователю, в статистических и исследовательских целях, а также для
            улучшения cервисов и функций ПО RuBeacon.<br><br>
            
            5.2. Пользователь осознает, что оборудование и программное обеспечение, используемые им для использования ПО RuBeacon
            могут обладать функцией запрещения операций с файлами cookie (для любых сайтов или для определенных сайтов), а также
            удаления ранее полученных файлов cookie.<br><br>
            
            5.3. RuBeacon вправе определить, что предоставление определенного сервиса или функции возможно лишь при условии, что
            прием и получение файлов cookie разрешены Пользователем.<br><br>
            
            5.4. Содержание, структура, технические параметры файла cookie определяются RuBeacon по своему усмотрению и могут
            изменяться без предварительного уведомления Пользователя в любое время.<br><br>
            
            5.5. (Если применимо) Счетчики, размещенные RuBeacon в ПО RuBeacon, могут использоваться для анализа файлов cookie
            Пользователя, для сбора, обработки и передачи RuBeacon статистической информации об использовании ПО RuBeacon, а также
            для обеспечения работоспособности Сервисов в целом или их отдельных функций в частности. Технические параметры работы
            счетчиков определяются RuBeacon и могут изменяться RuBeacon по своему усмотрению в любое время без предварительного
            уведомления Пользователя.<br><br>
            
            <hr>
            
            <b>6. Меры, применяемые для защиты персональной информации Пользователя.</b><br><br>
            
            6.1. RuBeacon принимает все необходимые технические и организационные меры для защиты персональной информации
            Пользователя от неправомерного или случайного доступа, уничтожения, изменения, блокирования, копирования,
            распространения, а также от иных неправомерных действий с ней третьих лиц.<br><br>
            
            <hr>
            
            <b>7. Изменение Политики конфиденциальности. Применимое законодательство.</b><br><br>
            
            7.1. RuBeacon имеет право вносить изменения в настоящую Политику конфиденциальности в любое время по своему усмотрению
            согласно требованиям применимого законодательства. При внесении изменений в актуальной редакции указывается дата
            последнего обновления. Новая редакция Политики вступает в силу с момента ее публикации на сайте RuBeacon
            (http://rbcn.mobi/), если иное не предусмотрено новой редакцией Политики.<br><br>
            
            7.2. К настоящей Политике и отношениям между Пользователем и RuBeacon, возникающим в связи с применением Политики
            конфиденциальности, подлежит применению право Российской Федерации.<br><br>
            
            <hr>
            
            <b>8. Обратная связь. Вопросы и предложения.</b><br><br>
            
            8.1. Все предложения или вопросы по поводу настоящей Политики Пользователь вправе направлять в Службу поддержки
            пользователей RuBeacon по email на адрес help@ru-beacon.ru.<br><br>
        </div>
    `;
    showPopup();
});

lib.handleNodesClick('#payment-rules', () => {
    popupContent.innerHTML = `
        <h3>Оплата с помощью банковской карты через «Альфа-Банк».</h3>
        
        <div class="popup-text">
            <p>
                Оплата предоставляется без комиссии. Платежи осуществляются перечислением денежных средств с
                банковских карт VISA и MASTERCARD при наличии возможности совершения интернет-платежей,
                предоставленных Вашим банком, выпустившим банковскую карту. О наличии возможности совершения
                интернет-платежей с использованием банковской карты, Вы можете узнать, обратившись в ваш банк.
                Ввод данных банковской карты осуществляется на защищенной платежной странице банка-партнера,
                предоставляющего услугу. Банком-партнером ООО «РуБикон» является ОАО «Альфа-Банк».
            </p>
            
            <p><u>Конфиденциальность</u></p>
            <p>
                1) Услуга оплаты через интернет осуществляется в соответствии с Правилами международных
                платежных систем на принципах соблюдения конфиденциальности и безопасности совершения платежа,
                для чего используются самые современные методы проверки, шифрования и передачи данных по
                закрытым каналам связи.
            </p>
            <p>
                2) Ввод данных банковской карты осуществляется на защищенной платежной странице банка-партнера,
                предоставляющего Услугу.
            </p>
            
            <p><u>Случаи отказа в совершении платежа: </u></p>
            <p>
                • банковская карта не предназначена для совершения платежей через интернет, о чем можно узнать,
                осведомившись в Вашем Банке;
            </p>
            <p>
                • данные банковской карты введены неверно;
            </p>
            <p>
                истек срок действия банковской карты. Срок действия карты, как правило, указан на лицевой
                стороне карты (это месяц и год, до которого действительна карта). Подробнее о сроке действия
                карты Вы можете узнать, обратившись в банк, выпустивший банковскую карту;
            </p>
            <p>
                • недостаточно средств для оплаты на банковской карте. Подробнее о наличии средств на
                банковской карте Вы можете узнать, обратившись в банк, выпустивший банковскую карту;
            </p>
            <p>
                • превышен установленный лимит операций за день. Сумма ежедневного лимита для всех операций
                определяется банком-партнером.
            </p>
            
            <p><u>
                Для корректного ввода необходимо внимательно и точно, соблюдая последовательность цифр и
                букв, ввести данные так, как они указаны на Вашей карте:
            </u></p>
            <p>
                владелец карты (как правило, указан на лицевой стороне банковской карты на английском языке
                заглавными буквами. Например, IVANOV IVAN);
            </p>
            <p>
                2. номер карты (как правило, указан на лицевой стороне банковской карты и состоит из 16-и
                цифр. Например: 0123 4567 8901 2345);
            </p>
            <p>
                3. срок действия карты (как правило, указан на лицевой стороне банковской карты - месяц и
                год, до которого действительна карта. Срок действия карты вводится цифрами. Например, 12
                (вводится в поле месяца) и 13 (вводится в поле года), что означает, что карта действительна
                до декабря 2013 года);
            </p>
            <p>
                4. CVV2 или CVC2 код карты (как правило, указан на обратной стороне банковской карты и
                состоит из 3-х цифр. Например, 123).
            </p>
        </div>
    `;
    showPopup();
});


lib.handleNodesClick('#legal-information-footer, #legal-information-header, #legal-information-header-tablet', () => {
    popupContent.innerHTML = `
        <h3>Правовая информация</h3>
        
        <div class="popup-text legal-information-popup-text">
            <p>ООО “Рубикон” - российская ИТ-компания, работающая с 2014 года. Мы разрабатываем ПО для заведений общественного питания и служб доставки, состоим в списке непрерывно действующих организаций и осуществляем техническую ИТ-поддержку организаций общественного питания, обеспечивающих население продуктами питания и товарами первой необходимости.</p>
            <p>Реализуем экземпляры программного продукта, разработанные ООО “Рубикон”, и предоставляем права на использование, в том числе путем предоставления удаленного доступа к программам и базам данных, включая их обновление и дополнительные функциональные возможности через информационно-телекоммуникационную сеть "Интернет".</p>
            <p>Выполняем работы по разработке, адаптации и модификации, а также работы по установке, тестированию и сопровождению программного продукта.</p>
            
            <p>О компании: </p>
            <p>Общество с ограниченной ответственностью «РуБикон»
ОГРН 1147746697075 
ИНН 7728881950
КПП 771001001
Генеральный директор: Парфенов И.С.</p>
<p>ООО “Рубикон” внесен в список аккредитованных ИТ-компаний № 11012 от 26.12.2019 г.</p>
<p>Основной вид деятельности:
ОКВЭД: 63.11.1 Деятельность по созданию и использованию баз данных и информационных ресурсов</p>
<p>Дополнительные виды деятельности:
62.01 Разработка компьютерного программного обеспечения
62.09 Деятельность, связанная с использованием вычислительной техники и информационных технологий, прочая
63.11 Деятельность по обработке данных, предоставление услуг по размещению информации и связанная с этим деятельность</p>
        </div>
    `;
    showPopup();
});