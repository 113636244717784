import * as lib from './lib'

let validPhone = false;
let validEmail = true;

const phoneInput = lib.getNode('#phone-input');
phoneInput.addEventListener('input', e => {
    const val = e.target.value;
    if (val.length < 4 || (val.length === 5 && ['7', '8'].includes(e.target.value.slice(-1)))) {
        e.preventDefault();
        e.target.value = '+7 ('
    } else {
        e.target.value = maskPhone(val);
    }
    lib.getNode('.form-phone-bg').innerText = e.target.value + '+7 (___) ___-__-__'.slice(e.target.value.length);
});

function maskPhone(val) {
    let masked = '';
    if (val.length === 1) {
        if (!['+', '7', '8'].includes(val[0])) {
            masked = `+7 (${val}`;
        } else if (['7', '8'].includes(val[0])) {
            masked = `+7 (${val.slice(1)}`;
        } else if (val[0] === '+') {
            masked = '+';
        }
    } else {
        masked = val.replace(/\D/g, '');
        const mask = '+7 (###) ###-##-##';
        for (let i in mask) {
            i = +i;
            if (masked.length >= i && mask[i] !== masked[i] && mask[i] !== '#') {
                masked = masked.slice(0, i) + mask[i] + masked.slice(i);
            }
        }
        if (masked[masked.length - 1] === '-') {
            masked = masked.slice(0, -1);
        } else if (masked[masked.length - 1] === ' ') {
            masked = masked.slice(0, -2);
        }
    }
    return masked;
}

phoneInput.addEventListener('blur', e => {
    const condition = e.target.value.length === 18;
    lib.getNode('#phone-error').style.display = condition ? 'none' : 'block';
    phoneInput.setAttribute('valid', condition);
    validPhone = condition;
    validateForm();
});

const emailInput = lib.getNode('#email-input');
emailInput.addEventListener('blur', e => {
    const str = e.target.value;
    const condition = !str || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(str);
    lib.getNode('#email-error').style.display = condition ? 'none' : 'block';
    emailInput.setAttribute('valid', condition);
    validEmail = condition;
    validateForm();
});

const formBtn = lib.getNode('#form-btn');
function validateForm() {
    formBtn.disabled = !(validPhone && validEmail);
}
const form = lib.getNode('.form');
const successMsg = lib.getNode('.form-success');
const errorMsg = lib.getNode('.form-error');
let xhr = null;

function serialize(obj) {
    let str = [];
    for (let p in obj) if (obj.hasOwnProperty(p)) {
        str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
    return str.join("&");
}

lib.handleNodesClick('#form-btn', e => {
    e.preventDefault();

    // antispam, password is actually honeypot
    if (xhr || form.password.value) {
        return;
    }

    xhr = new XMLHttpRequest();
    xhr.open("POST", "https://rbcn.mobi/iiko_biz_submit?short_response=1");
    xhr.setRequestHeader("Content-Type", "application/x-www-form-urlencoded;charset=utf-8");
    let data = serialize({
        "email": form.email.value,
        "phone": form.phone.value,
        "name": form.name.value,
        "format": form.format.value
    });

    xhr.onreadystatechange = function () {
        if (xhr.readyState == 4) {
            const success = JSON.parse(xhr.responseText);
            form.style.display = 'none';
            if (success) {
                successMsg.style.display = 'block';
            } else {
                errorMsg.style.display = 'block';
            }
            xhr = null;
        }
    };
    xhr.send(data);
});

lib.handleNodesClick('#form-return', () => {
    form.style.display = 'block';
    errorMsg.style.display = 'none';
});