import EmblaCarousel from 'embla-carousel'
import * as lib from './lib'

const wrap = lib.getNode('.embla')
const viewPort = wrap.querySelector(".embla__viewport");
const options = { loop: true, align: "start" }

const embla = EmblaCarousel(viewPort, options)

embla.on('select', (eventName) => {
    lib.getNodes('.slide').forEach(n => {
        n.classList.remove('slide-active');
    });
    lib.getNode(`#slide_${embla.selectedScrollSnap() + 1}`).classList.add('slide-active');
});

export {embla}