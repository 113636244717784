const getNode = (selector) => document.querySelector(selector);

const getNodes = (selector) => document.querySelectorAll(selector);

const toggleVisibility = (node) => node.hidden = !node.hidden;

const handleNodesClick = (selector, cb) => {
    getNodes(selector).forEach(n => {
        n.addEventListener('click', e => {
            cb(e);
        });
    });
}

const isMobile = () => /Mobile|webOS|BlackBerry|IEMobile|MeeGo|mini|Fennec|Windows Phone|Android|iP(ad|od|hone)/i.test(navigator.userAgent);

export {getNode, getNodes, toggleVisibility, handleNodesClick, isMobile}