import './styles/index.scss';
import * as lib from './js/lib'
import * as carousel from './js/carousel'
import './js/quiz'
import './js/form'
import './js/popup'
import * as Stickyfill from "stickyfilljs";

const overlay = lib.getNode('.overlay');
const toggleMenu = () => {
    const menu = lib.getNode('.menu');
    const burger = lib.getNode('.menu-burger');
    const close = lib.getNode('.menu-close');
    [overlay, menu, burger, close].forEach(n => {
        lib.toggleVisibility(n)
    });
    const body = lib.getNode('body');
    body.style.overflowY = !overlay.hidden ? 'hidden' : 'scroll';
}
const menuTrigger = lib.getNode('.menu-trigger');
menuTrigger.addEventListener('click', toggleMenu);
overlay.addEventListener('click', toggleMenu);
lib.handleNodesClick('.menu-link', e => {
    if (!overlay.hidden) toggleMenu();
    const id = e.target.id.split('-')[0];
    const top = lib.getNode(`#${id}`).getBoundingClientRect().top + window.pageYOffset;
    const offset = lib.isMobile() ? 100 : 20;
    setTimeout(() => {
       window.scrollTo({top: top - offset, behavior: "smooth"});
    }, 200);
});
lib.handleNodesClick('.menu-btn', e => {
    if (!overlay.hidden) toggleMenu();
    const top = lib.getNode(`#form`).getBoundingClientRect().top + window.pageYOffset;
    setTimeout(() => {
        window.scrollTo({top: top - 100, behavior: "smooth"});
    }, 200);
});
lib.handleNodesClick('.navbar-logo', e => {
    window.scrollTo({top: 0, behavior: "smooth"});
});

let selectedAdvantage = 1;
let selectedProduct = 'app';
const selectAdvantage = (product, number) => {
    selectedAdvantage = number;
    selectedProduct = product;
    lib.getNodes('.advantage-active').forEach(n => {
        n.classList.remove('advantage-active');
    });
    const advantage = lib.getNode(`#${product}-advantage-${number}`);
    if (!advantage) return;
    advantage.classList.add('advantage-active');
    lib.getNode(`#${product}-advantage-mark`).style.top = `${(number-1)*77.66666666666667}px`;
    if (product === 'app') {
        lib.getNode('#app_screen_1').innerHTML = `
        <source srcset="./assets/images/app/${number}-1.webp" type="image/webp">
        <source srcset="./assets/images/app/${number}-1.png" type="image/jpeg">
        <img src="./assets/images/app/${number}-1.png" alt="приложение iOS">
    `;
        lib.getNode('#app_screen_2').innerHTML = `
        <source srcset="./assets/images/app/${number}-2.webp" type="image/webp">
        <source srcset="./assets/images/app/${number}-2.png" type="image/jpeg">
        <img src="./assets/images/app/${number}-2.png" alt="приложение Android">
    `;
    } else {
        if (+number < 6) {
            lib.getNode('.advantage-screens-site').innerHTML = `
                <picture class="advantage-screens-site-laptop">
                    <source srcset="./assets/images/site/laptop.webp" type="image/webp">
                    <source srcset="./assets/images/site/laptop.png" type="image/jpeg">
                    <img src="./assets/images/site/laptop.png" alt="десктоп">
                </picture>
                <picture class="advantage-screens-site-laptop-screen">
                    <source srcset="./assets/images/site/${number}.webp" type="image/webp">
                    <source srcset="./assets/images/site/${number}.png" type="image/jpeg">
                    <img src="./assets/images/site/${number}.png" alt="сайт">
                </picture>
            `;
        } else {
            lib.getNode('.advantage-screens-site').innerHTML = `
                <picture class="advantage-screen-site-mobile">
                    <source srcset="./assets/images/app/1-1.webp" type="image/webp">
                    <source srcset="./assets/images/app/1-1.png" type="image/jpeg">
                    <img src="./assets/images/app/1-1.png" alt="приложение iOS">
                </picture>
                <picture class="advantage-screen-site-mobile">
                    <source srcset="./assets/images/app/1-2.webp" type="image/webp">
                    <source srcset="./assets/images/app/1-2.png" type="image/jpeg">
                    <img src="./assets/images/app/1-2.png" alt="приложение Android">
                </picture>
            `;
        }
    }
}

const handleTab = product => {
    const opposite = product === 'app' ? 'site' : 'app';
    const tab = lib.getNode(`#${product}-tab`);
    tab.addEventListener('click', (e) => {
        lib.getNode('.advantages-tab-active').classList.remove('advantages-tab-active');
        e.target.classList.add('advantages-tab-active');
        lib.getNode('.advantages-tabs-line-active').style.left = product === 'app' ? '0' : '50%';
        lib.getNode(`#${opposite}-advantages`).style.opacity = '0';
        lib.getNode(`#${product}-advantages`).style.display = 'flex';
        lib.getNode(`#${product}-advantage-mark`).style.top = '0';
        const activeNodes = lib.getNodes(`#${product}-advantages .advantage-active`);
        if (activeNodes.length) {
            activeNodes.forEach(n => {
                if (n.id !== `${product}-advantage-1`) {
                    n.classList.remove('advantage-active');
                }
            })
        }
        selectAdvantage(product, 1);
        setTimeout(() => {
            lib.getNode(`#${product}-advantages`).style.opacity = '1';
        }, 200);
        setTimeout(() => {
            lib.getNode(`#${opposite}-advantages`).style.display = 'none';
        }, 300);
    });
}
handleTab('app');
handleTab('site');

if (window.innerWidth >= 1024) {
    lib.handleNodesClick('.advantage', e => {
        const [product, , number] = e.target.id.split('-');
        selectAdvantage(product, number);
    });
}

lib.handleNodesClick('.reviews-tab', e => {
    const number = e.target.id.split('-').pop();
    const lineLength = lib.getNode('.reviews-tabs-line').offsetWidth;
    lib.getNode('.reviews-tabs-line-active').style.left = Math.round((number-1)*Math.ceil((lineLength + (lineLength-108*5)/4)/5)) + 'px';

    const reviewContainer = lib.getNode('.review-container');
    let opacity = 1;
    const timer = setInterval(function() {
        if(opacity <= 0.1) {
            clearInterval(timer);
            changeTab();
        }
        reviewContainer.style.opacity = opacity;
        opacity -= 0.1;
    }, 10);

    function changeTab() {
        const ext = number > 2 ? 'png' : 'jpg';
        lib.getNode('.review-screen').innerHTML = `
        <source srcset="./assets/images/clients/app${number}.webp" type="image/webp">
        <source srcset="./assets/images/clients/app${number}.${ext}" type="image/jpeg">
        <img src="./assets/images/clients/app${number}.${ext}" alt="приложение ресторана">
    `;
        const headings = [
            'Батони - Москва',
            'Dellos',
            'Krispy Kreme',
            'Sapore Italiano',
            'Prime'
        ];
        lib.getNode('.review-heading').innerText = headings[number-1];
        const reviews = [
            'Работаю с командой RuBeacon уже более 4-х лет. Отличное ведение проекта, постоянная тех. поддержка, что очень упрощает работу.\n' +
            '\n' +
            'Запуск промо-акций и лояльностей для заказчиков приложения – очень удобно.\n' +
            '\n' +
            'Постоянные обновления и улучшения, доработки приложения, очень много внимания уделяется пользовательскому интерфейсу.',
            'От техподдержки только хорошие впечатления! Всегда получалось оперативно решать все вопросы. \n' +
            '\n' +
            'Ну а самая большая похвала - это то, что в техподдержку приходится обращаться крайне редко, потому что все работает отлично)',
            'Настоящим письмом компания ООО «Донатс кафе» (официальный представитель компании Krispy Kreme в России) выражает признательность команде ООО «РуБикон» за работу над проектом по разработке мобильного приложения на доставку тающих во рту пончиков Криспи Крим.\n' +
            '\n' +
            'В процессе работы сотрудники ООО «РуБикон» на всех уровнях демонстрировали профессионализм, нетривиальный подход, инициативу и оперативность в решении сложных задач. \n' +
            '\n' +
            'Особенно хотелось бы отметить отличный результат работы - на 100% удобное приложение с хорошим потенциалом развития.\n' +
            '\n' +
            'Желаем Вам процветания и достойных новых проектов!',
            'Сотрудничаем с компанией Рубикон по двум проектам и смогли попробовать и сайт, и приложение. Огромный плюс для нас — это интеграция с Iiko и скорость подключения: сделали запрос и, буквально на следующий день, - все готово.\n' +
            '\n' +
            'Обратная связь по всем вопросам 24/7 с живыми людьми, что также немаловажно, так как, взаимодействуя с другими подрядчиками, очень сложно решить технический вопрос, и уходит много времени на объяснения только ситуации.\n' +
            '\n' +
            'Также радует, что все идеи и необходимые доработки всегда можно обсудить и найти вместе варианты решения.',
            'С компанией RuBeacon мы работаем уже несколько лет, в работе с ними в первую очередь нравится четкое и оперативное решение поставленных задач, а также наличие личного менеджера, который готов помочь в любой момент по любому вопросу. \n' +
            '\n' +
            'Ну и конечно же сам продукт, современное, красивое приложение со всеми функциями, которые важны и нужны пользователям. \n' +
            '\n' +
            'Ребятам желаю роста и развития в своем направлении!'
        ];
        lib.getNode('.review').innerText = reviews[number-1];
        const clients = [
            'Борис Эксузян',
            'Климов Павел',
            'Майя Шевченко',
            'Марина Баландина',
            'Галахова Светлана'
        ];
        lib.getNode('.review-client').innerText = clients[number-1];
        const posts = [
            'Директор доставки сети «Батони»',
            'Менеджер компании ООО “Д-Деливери”',
            'Руководитель департамента маркетинга',
            'Директор доставки в Sapore Italiano',
            'Генеральный директор'
        ];
        lib.getNode('.review-client-post').innerText = posts[number-1];

        const timer2 = setInterval(function() {
            if(opacity >= 1) {
                clearInterval(timer2);
            }
            reviewContainer.style.opacity = opacity;
            opacity += 0.05;
        }, 10);
    }
});

let selectedProject = 1;
const selectProject = (number) => {
    selectedProject = number;
    lib.getNodes('.project-active').forEach(n => {
        n.classList.remove('project-active');
    });
    lib.getNode('.project-screen').innerHTML = `
        <source srcset="./assets/images/projects/desktop/${number}.webp" type="image/webp">
        <source srcset="./assets/images/projects/desktop/${number}.png" type="image/png">
        <img src="./assets/images/projects/desktop/${number}.png" alt="iphone">
    `;
    lib.getNode(`#project-${number}`).classList.add('project-active');
    const lineLength = lib.getNode('.projects-line').offsetHeight;
    lib.getNode('.projects-line-active').style.top = Math.round((number-1)*Math.ceil((lineLength + (lineLength-80*7)/6)/7)) + 'px';
}

lib.handleNodesClick('.project', e => {
    const number = e.target.id.split('-').pop();
    selectProject(number);
});


const adv = lib.getNode('#advantages');
let oldY = 0;
let timer = null;
const keys = {37: 1, 38: 1, 39: 1, 40: 1};

function preventDefault(e) {
    e.preventDefault();
}

function preventDefaultForScrollKeys(e) {
    if (keys[e.keyCode]) {
        preventDefault(e);
        return false;
    }
}

let supportsPassive = false;
try {
    window.addEventListener("test", null, Object.defineProperty({}, 'passive', {
        get: function () { supportsPassive = true; }
    }));
} catch(e) {}

const wheelOpt = supportsPassive ? { passive: false } : false;
const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

function disableScroll() {
    window.addEventListener('DOMMouseScroll', preventDefault, false); // older FF
    window.addEventListener(wheelEvent, preventDefault, wheelOpt); // modern desktop
    window.addEventListener('touchmove', preventDefault, wheelOpt); // mobile
    window.addEventListener('keydown', preventDefaultForScrollKeys, false);
}

function enableScroll() {
    window.removeEventListener('DOMMouseScroll', preventDefault, false);
    window.removeEventListener(wheelEvent, preventDefault, wheelOpt);
    window.removeEventListener('touchmove', preventDefault, wheelOpt);
    window.removeEventListener('keydown', preventDefaultForScrollKeys, false);
}

Stickyfill.forceSticky();

if (window.innerWidth >= 1024) {
    let offset = 20;
    if (window.innerWidth < 1200) {
        if (window.matchMedia("(orientation: portrait)").matches) {
            offset = 300;
        }
    }

    window.addEventListener('scroll', (e) => {
        const newY = window.pageYOffset;
        const top = adv.getBoundingClientRect().top;
        const bottom = adv.getBoundingClientRect().top;
        if (top > 0 && (newY < oldY || top <= offset) && bottom > 0) {
            disableScroll();
            if (timer) return;
            let number = newY > oldY ? +selectedAdvantage + 1 : +selectedAdvantage - 1;
            if (number > 0 && number < 7) selectAdvantage(selectedProduct, number);
            oldY = newY;
            if (selectedAdvantage === 0 || selectedAdvantage === 6) {
                enableScroll();
            } else {
                timer = setTimeout(() => {
                    clearTimeout(timer);
                    timer = null;
                    enableScroll();
                }, 300);
            }
        }
    });
}


let projectsTimer = null;
const projectsSection = lib.getNode('#projects');
let offset = 20;
if (window.innerWidth < 1200) {
    if (window.innerWidth < 768) {
        offset = 100;
    } else if (window.innerWidth < 1024) {
        offset = 200;
    } else {
        if (window.matchMedia("(orientation: landscape)").matches) {
            offset = 20;
        } else {
            offset = 350;
        }
    }
}

Stickyfill.add([projectsSection, adv]);

window.addEventListener('scroll', (e) => {
    const newY = window.pageYOffset;
    const top = projectsSection.getBoundingClientRect().top;
    const bottom = projectsSection.getBoundingClientRect().top;

    if (top > 0 && (newY < oldY || top <= offset) && bottom > -200) {
        disableScroll();
        if (projectsTimer) return;
        let number = newY > oldY ? +selectedProject + 1 : +selectedProject - 1;
        if (window.innerWidth >= 1024) {
            if (number > 0 && number < 8) selectProject(number);
            oldY = newY;
            if (number === 0 || number === 8) {
                enableScroll();
            } else {
                projectsTimer = setTimeout(() => {
                    clearTimeout(projectsTimer);
                    projectsTimer = null;
                    enableScroll();
                    if (selectedAdvantage === 6) {
                    }
                }, 300);
            }
        } else {
            if (newY > oldY) {
                // selectedProject++;
                carousel.embla.scrollNext()
            } else {
                // selectedProject--;
                carousel.embla.scrollPrev();
            }
            oldY = newY;
            projectsTimer = setTimeout(() => {
                clearTimeout(projectsTimer);
                projectsTimer = null;
                enableScroll();
                if (selectedAdvantage === 6) {
                }
            }, 800);
        }
    }
});

function customResize() {
    const resizeLegalBlock = (marginDeltaFirst) => {
        try {
            const mainBlock = document.getElementsByTagName('main')[0]
            const mainBlockParams = mainBlock.getBoundingClientRect()
            const mainBlockMargin = mainBlockParams.left

            let legalBlock = document.getElementById('company-legal-info')
            const legalBlockParams = legalBlock.getBoundingClientRect()

            const marginDelta = mainBlockParams.width - legalBlockParams.width
            const calcMargin = mainBlockMargin + (marginDelta > 0 ? marginDelta : marginDeltaFirst)

            legalBlock.style['margin-left'] = `-${calcMargin}px`
            legalBlock.style['margin-right'] = `-${calcMargin}px`
            legalBlock.style['padding-left'] = `${calcMargin}px`
            legalBlock.style['padding-right'] = `${calcMargin}px`
            // legalBlock.style.width = window.innerWidth - mainBlockMargin*2 + 'px'

        } catch (err) {
            console.log("An error occur while resizing legal block")
        }
    }


    setTimeout(() => {
        const mainBlockWidth = document.getElementsByTagName('main')[0].getBoundingClientRect().width
        const legalBlockWidth = document.getElementById('company-legal-info').getBoundingClientRect().width
        const legalMarginDelta = mainBlockWidth - legalBlockWidth

        resizeLegalBlock(legalMarginDelta)
        window.addEventListener('resize', () => {
            resizeLegalBlock(legalMarginDelta)
        })
    }, 1)
}

customResize()