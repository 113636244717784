import * as lib from './lib'

let quiz = {
    step: 1,
    product: '',
    integration: 'iiko',
    delivery: true,
    takeaway: true,
    loyalty: false,
    ordersNumber: 0,
    venuesNumber: 0
};
let price = 0;

lib.handleNodesClick('.quiz-heading', e => {
    prevStep();
});

lib.handleNodesClick('#quiz-step-1 .btn', e => {
    quiz.product = e.target.id.split('-').pop();
});
lib.handleNodesClick('.quiz-radio', e => {
    quiz.integration = e.target.value;
});
lib.handleNodesClick('#quiz-step-3 .quiz-checkbox', e => {
    const option = e.target.id.split('-').pop();
    quiz[option] = !quiz[option];
    let btnDisabled = !quiz.delivery && !quiz.takeaway && !quiz.loyalty;
    if (quiz.product === 'all' && quiz.integration === 'iiko' && quiz.loyalty && !quiz.delivery && !quiz.takeaway) {
        btnDisabled = true;
    }
    lib.getNode(`#quiz-step-3 .quiz-forward`).disabled = btnDisabled;
});
lib.getNode('#orders-number').addEventListener('input', e => {
    quiz.ordersNumber = +e.target.value;
    lib.getNode(`#quiz-step-4 .quiz-forward`).disabled = !quiz.ordersNumber || !quiz.venuesNumber;
    if (quiz.ordersNumber) {
        if (!lib.getNode(`#quiz-dot-5`).classList.contains('quiz-dots-dot-active')) {
            lib.getNode(`#quiz-dot-5`).classList.add('quiz-dots-dot-active');
            lib.getNode(`#quiz-dot-line-5`).classList.add('quiz-dots-line-active');
        }
    } else {
        lib.getNode(`#quiz-dot-5`).classList.remove('quiz-dots-dot-active');
        lib.getNode(`#quiz-dot-line-5`).classList.remove('quiz-dots-line-active');
    }
});
lib.getNode('#venues-number').addEventListener('input', e => {
    quiz.venuesNumber = +e.target.value;
    lib.getNode(`#quiz-step-4 .quiz-forward`).disabled = !quiz.ordersNumber || !quiz.venuesNumber;
});
lib.handleNodesClick('.quiz-restart', e => {
    quiz.step = 1;
    lib.getNodes(`.quiz-dots-dot`).forEach(d => {
        if (d.id !== 'quiz-dot-1') {
            d.classList.remove('quiz-dots-dot-active');
        }
    });
    lib.getNodes(`.quiz-dots-line`).forEach(d => {
        d.classList.remove('quiz-dots-line-active');
    });
    showStep();
});
lib.handleNodesClick('.quiz-submit', e => {
    lib.getNode('#form').scrollIntoView({behavior: "smooth"});
});

const showStep = () => {
    lib.getNodes('.quiz-step').forEach(n => n.classList.remove('quiz-step-active'));
    lib.getNode(`#quiz-step-${quiz.step}`).classList.add('quiz-step-active');
    const dots = lib.getNode('.quiz-dots');
    const endButtons = lib.getNode('.quiz-end-buttons');
    if (quiz.step === 3) {
        lib.getNode('#quiz-loyalty + label')
            .style.display = !['app', 'all'].includes(quiz.product) || quiz.integration !== 'iiko' ? 'none' : 'flex';
    }
    if (quiz.step === 5) {
        lib.getNode('#quiz-payment-bank').style.display = 'flex';
        dots.style.display = 'none';
        endButtons.hidden = false;
        let appPrice = 0;
        let sitePrice = 0;
        if (quiz.product === 'app' || quiz.product === 'all') {
            lib.getNode('#quiz-payment-appstore').style.display = 'flex';
            if (quiz.integration === 'iiko') {
                lib.getNode('#quiz-payment-app').style.display = 'flex';

                let loyaltyPrice = 0;
                if (quiz.loyalty) {

                    if (quiz.product === 'app' && !quiz.delivery && !quiz.takeaway) {
                        lib.getNode('#quiz-payment-bank').style.display = 'none';
                    }

                    loyaltyPrice = 2990;
                    let venues = quiz.venuesNumber - 1;
                    loyaltyPrice += Math.floor(venues / 10) * 7990;
                    venues = venues % 10;
                    if (venues > 2) {
                        loyaltyPrice += Math.floor(venues / 3) * 2790;
                        venues = venues % 3;
                    }
                    if (venues > 0) {
                        loyaltyPrice += venues * 990;
                    }
                }

                let venuesPrice = 0;
                if (quiz.delivery || quiz.takeaway) {
                    venuesPrice = 9990;
                    let venues = quiz.venuesNumber - 3;
                    if (venues > 2) {
                        venuesPrice += Math.floor(venues / 3) * 7990;
                        venues = venues % 3;
                    }
                    if (venues > 0) {
                        venuesPrice += venues  * 2990;
                    }
                }

                appPrice = loyaltyPrice + venuesPrice;
            } else {
                lib.getNode('#quiz-payment-app').style.display = 'none';
                if (quiz.integration === 'poster') {
                    appPrice = 6990;
                    let venues = quiz.venuesNumber - 2;
                    if (venues > 0) {
                        appPrice += venues * 3000;
                    }
                } else {
                    const k = quiz.delivery ? 8 : 2;
                    appPrice = quiz.ordersNumber * 30 * k;
                }
            }
        } else {
            lib.getNode('#quiz-payment-appstore').style.display = 'none';
            lib.getNode('#quiz-payment-app').style.display = 'none';
        }

        if (['app', 'all'].includes(quiz.product) && !['iiko', 'poster'].includes(quiz.integration)) {
            lib.getNode('#quiz-payment-monthly').style.display = 'flex';
        } else {
            lib.getNode('#quiz-payment-monthly').style.display = 'none';
        }

        if (quiz.product === 'site' || quiz.product === 'all') {
            lib.getNode('#quiz-payment-site').style.display = 'flex';
            lib.getNode('#quiz-payment-domain').style.display = 'flex';
            const k = quiz.delivery ? 32 : 8;
            sitePrice += quiz.ordersNumber * 30 * 0.8 * k;
        } else {
            lib.getNode('#quiz-payment-site').style.display = 'none';
            lib.getNode('#quiz-payment-domain').style.display = 'none';
        }

        price = appPrice + sitePrice;
        if (price > 50000 && !['iiko', 'poster'].includes(quiz.integration)) {
            lib.getNode('.quiz-negotiate').style.display = 'block';
        } else {
            lib.getNode('.quiz-negotiate').style.display = 'none';
        }
    } else {
        lib.getNode('.quiz-negotiate').style.display = 'none';
        lib.getNode(`#quiz-dot-5`).classList.remove('quiz-dots-dot-active');
        lib.getNode(`#quiz-dot-line-5`).classList.remove('quiz-dots-line-active');
        dots.style.display = 'flex';
        endButtons.hidden = true;
    }
    const headings = [
        'Что запускаем?',
        'Какую кассовую систему используем?',
        'Что включаем?',
        'Сколько заказов в день?'
    ]
    const headingNode = lib.getNode('.quiz-heading');
    headingNode.innerText = quiz.step === 5 ? `~ ${price.toLocaleString()} ₽/месяц` : headings[quiz.step - 1];
}

const nextStep = () => {
    quiz.step++;
    showStep();
    if (quiz.step === 2) {
        lib.getNode('.quiz-heading').classList.add('quiz-heading-with-arrow');
    }
    let step = quiz.step === 5 ? 6 : quiz.step;
    lib.getNode(`#quiz-dot-${step}`).classList.add('quiz-dots-dot-active');
    if (quiz.step < 2) return;
    lib.getNode(`#quiz-dot-line-${step}`).classList.add('quiz-dots-line-active');
}

const prevStep = () => {
    lib.getNode(`#quiz-dot-${quiz.step}`).classList.remove('quiz-dots-dot-active');
    lib.getNode(`#quiz-dot-line-${quiz.step}`).classList.remove('quiz-dots-line-active');
    if (quiz.step === 5) {
        lib.getNode(`#quiz-dot-6`).classList.remove('quiz-dots-dot-active');
        lib.getNode(`#quiz-dot-line-6`).classList.remove('quiz-dots-line-active');
    }
    quiz.step--;
    showStep();
    if (quiz.step === 1) {
        lib.getNode('.quiz-heading').classList.remove('quiz-heading-with-arrow');
    }
}

lib.handleNodesClick('#quiz-step-1 .btn', e => {
    quiz.platform = e.target.id.split('-').pop();
    nextStep();
});

lib.handleNodesClick('.quiz-forward', e => {
    nextStep();
});